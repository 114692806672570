<template>
  <div class="home-container">
    <div class="linear-bg">
      <div class="home-title"></div>
    </div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <img class="banner_img" :src="v.url" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
          class="notice-swipe"
          left-icon="bullhorn-o"
          background="#ffffff"
          color="#f806e4"
          :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{ $t("index.task") }}</span>
          </div>
          <div @click="gotoMenu('/Game')">
            <span>{{ $t("index.more") }}</span>
            <van-icon name="arrow" color="#979799" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border="false" :column-num="4" :icon-size="20">
            <van-grid-item
              @click="toLottery(v.key, v.id)"
              v-for="(v, key) in gameitem"
              :key="key"
            >
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <span>{{ v.name }}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <van-pull-refresh
        :pulling-text='$t("list.pulling")'
        :loosing-text='$t("list.loosing")'
        :loading-text='$t("list.loading")'
        :success-text='$t("list.success")'
        v-model="isLoading"
        @refresh="onRefresh"
      >
        <div style="display: none;">
          <div class="hot-recommend">
            <div class="hot-title-div">
              <div>
                <span>{{ $t("index.hot") }}</span>
              </div>
              <div>
                <span @click="gotoMenu('/Video')">{{ $t("index.more") }}</span>
                <van-icon name="arrow" color="#979799" />
              </div>
            </div>
            <div class="movie_list_0">
              <swiper class="movie_swiper" :options="movielistSwiperOption">
                <swiper-slide v-for="(v, key) in movielist_0" :key="key">
                  <van-image
                    class="movie_cover"
                    @click="toPlayVideo(v.id)"
                    round
                    :src="v.cover"
                  >
                    <template v-slot:loading>
                      <van-loading type="circular" />
                    </template>
                  </van-image>
                  <img class="hot" v-if="key === 0" src="/img/home/no1.png" />
                  <img class="hot" v-if="key === 1" src="/img/home/no2.png" />
                  <img class="hot" v-if="key === 2" src="/img/home/no3.png" />
                  <div class="movie-list-item-bottom">
                    <div class="movie-time-div">
                      <span>{{ v.title }}</span>
                      <div class="van-count-down">{{ v.time }}</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="hot-title-div">
              <div>
                <span>{{ $t("index.more") }}</span>
              </div>
              <div @click="gotoMenu('/Video')">
                <span>{{ $t("index.recmonmand") }}</span>
                <van-icon name="arrow" size="25" color="#979799" />
              </div>
            </div>
            <div class="movie_list_1">
              <div
                class="movie-list-item"
                v-for="(v, key) in movielist_1"
                :key="key"
                @click="toPlayVideo(v.id)"
              >
                <van-image class="cover_img" round :src="v.cover" fit="cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{ v.title }}</span>
                    <span>{{ $t("video.play") }}:{{ v.count }}</span>
                  </div>
                </div>
              </div>
              <div class="hot-recommend-more" @click="gotoMenu('/Video')">
                {{ $t("index.more") }}
              </div>
            </div>
          </div>
        </div>
        <div class="hot-recommend">
          <van-col span="12" v-for="(item, index) in hotG" :key="index">
            <div style="margin: 10px 8px 0px;border: 1px solid #f23ed0;height: 350px;overflow: auto;border-radius: 8px;"  @click="toGInfo(item)">
              <div
                style="margin: 10px 10px 0px 10px;padding: 0px;position: relative;"
              >
                <img
                  :src="item.img_url"
                  style="width: 100%;height: 200px;object-fit: cover;"
                />
                <div
                  style="background-color: rgb(000, 000, 000,0.8);position:absolute;top: 0px;left: 0px;line-height:38px;width: 100%;padding: 0px 10px;text-align: left;white-space:nowrap;overflow:hidden;color: #fff;"
                >
                  {{ item.yn_yu }}
                </div>
              </div>
              <div style="margin: 0px 10px">
                <div
                  style="float: left;width: 30px;height: 30px;"
                  v-for="i in item.x"
                  :key="i"
                >
                  <img
                    src="../images/item/g0.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px;display: none;">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g3.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 24px;font-weight: 600;"
                >
                  {{ item.o }}
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g1.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 2;font-weight: 600;"
                >
                  {{ showAddress(item.class_id) }}
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g2.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 2.2;font-weight: 600;"
                >
                  {{ item.p }}
                </div>
                <div style="clear: both;" />
              </div>
            </div>
          </van-col>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      hotG: [],
      addlist: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        //effect: 'coverflow',

        slidesPerView: 1,
        speed: 800,
        autoplay: true,
        //loop:true,
      },
    };
  },
  methods: {
	toGInfo(data){
      let vod_name = ''
      this.addlist.forEach(element => {
        if(data.class_id == element.id)vod_name = element.yn_yu
      });
       this.$router.push({ path: '/profile?id=' + data.id + '&name=' + vod_name + '&adsid=' + data.class_id });
     // http://192.168.12.186:8080/#/profile?id=8&name=B%E1%BA%AFc%20Ninh&adsid=32
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
        this.getxuanfeihome();
      });
    },
    showAddress(id) {
      let result = id;
      this.addlist.forEach((element) => {
        if (id == element.id) result = element.yn_yu;
      });
      return result;
    },
    getxuanfeihome() {
      this.hotG = [];
      this.$http({
        method: "get",
        url: "xuanfeihome",
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.home == 1) {
            try {
              item.x = parseInt(item.xuanfei_name.split("^")[2]);
              if (isNaN(item.x)) item.x = 1;
            } catch (error) {
              console.log(error);
              item.x = 1;
            }
            try {
              item.o = parseInt(item.xuanfei_name.split("^")[0]);
              if (isNaN(item.o)) item.o = 18;
            } catch (error) {
              console.log(error);
              item.o = 18;
            }
            try {
              item.p = item.xuanfei_name.split("^")[1];
            } catch (error) {
              console.log(error);
            }
            this.hotG.push(item);
          }
        });
        console.log(this.hotG);
      });
    },
    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({
          path: "/Login",
        });
      } else {
        this.$router.push({
          path: "/Lottery?key=" + key + "&id=" + id,
        });
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({
          path: "/Login",
        });
      } else {
        this.$router.push({
          path: "/PlayVideo?id=" + id,
        });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Làm mới thành công");
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        this.getNotice(this.basicData); //获取公告
        this.getBanner(this.basicData); //获取banner
        this.getGameItem(); //获取首页游戏列表
        //this.getMovieList_0(this.basicData); //获取首页视频0
        //this.getMovieList_1(this.basicData); //获取首页视频1
        this.getAddress();
      });
    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        this.gameitem = res.data;
      });
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0;
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1;
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    this.getBasicConfig();
  },
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 250px;
  background: linear-gradient(270deg, #f806e4, #f806e4);
  background: #fff;
}
.home-title {
  background-image: url(/img/logo.jpg);
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: 4px;
  background-position: center;
  width: 100%;
  text-align: left;
  padding-left: 100px;
  height: 90px;
  line-height: 90px;
  color: #fff;
  font-weight: 600;
  font-size: 40px;
}
.home-container {
  position: absolute !important;
  top: 0px;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -20%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div > div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #f806e4;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}

.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 490px;
}
.movie_list_1 .movie-list-item .cover_img img {
  object-fit: cover;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;
}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}

.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
