<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" title="SAKURA" />
    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab :title="$t('concubine.city')">
          <div
            style="width: 100%;background-color: #fff;margin: 10px auto;padding: 10px;"
            v-if="xuanfeidata && xuanfeidata.length > 0"
          >
            <swiper class="banner_swiper" :options="bannerSwiperOption">
              <swiper-slide v-for="(v, k) in xuanfeidata" :key="k">
                <div style="width: 100%;height: 100%;position: relative;" @click="toGInfo(v)">
                  <img class="banner_img" :src="v.img_url" />
                  <div style="background-color: rgb(000, 000, 000,0.8);position:absolute;bottom: 0px;left: 0px;line-height:38px;width: 100%;padding: 0px 10px;text-align: left;white-space:nowrap;overflow:hidden;">
                    {{ v.yn_yu }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="card" style="display: none;">{{ $t("concubine.city_tip") }}</div>
          <div class="address">
            <van-cell-group v-for="(val, key) in addlist" :key="key">
              <van-cell @click="addgo(val)">{{ val.name }}</van-cell>
            </van-cell-group>
            <!-- <van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row> -->
          </div>
        </van-tab>
        <van-tab :title="$t('concubine.price')">
          <div class="card">
            {{ $t("concubine.city_tip") }}
            
          </div>
          <div class="rig-box">
            <p class="rig-title">{{ $t("concubine.pri_resource") }}</p>
            <p class="rig-content">{{ $t("concubine.pri_obj") }}</p>
            <p class="rig-title">{{ $t("concubine.pri_service") }}</p>
            <p class="rig-content">{{ $t("concubine.pric_service_one") }}</p>
            <p class="rig-content">{{ $t("concubine.pric_service_two") }}</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addlist: [],
      xuanfeidata: null,
      bannerSwiperOption: {
        slidesPerView: 1,
        speed: 800,
        autoplay: true,
      },
    };
  },
  methods: {
    toGInfo(data){
      let vod_name = ''
      this.addlist.forEach(element => {
        if(data.class_id == element.id)vod_name = element.yn_yu
      });
       this.$router.push({ path: '/profile?id=' + data.id + '&name=' + vod_name + '&adsid=' + data.class_id });
     // http://192.168.12.186:8080/#/profile?id=8&name=B%E1%BA%AFc%20Ninh&adsid=32
    },
    addgo(data) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({
          path: "/list?id=" + data.id + "&name=" + data.name,
        });
      }
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
      });
    },
    getxuanfeihot() {
      this.$http({
        method: "get",
        url: "xuanfeihot",
      }).then((res) => {
        this.xuanfeidata = res.data;
        console.log(this.xuanfeidata);
      });
    },
  },
  created() {
    this.getAddress();
  },
  mounted() {
    this.getxuanfeihot();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg, #f806e4, #e6c3a1);

  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
// /deep/ .van-col{
// padding: 30px 0px;
// }
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
  height: max-content;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #f806e4;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}

/deep/ .van-cell {
  padding: 30px 22px;
  font-size: 30px;
  line-height: 30px;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20px;
  // margin-top: 10px;
}
.address {
  width: 94%;
  margin: 0 auto;
  margin-bottom: 110px;
}
</style>
<style lang="less" scoped>
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 800px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
