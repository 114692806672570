<template>
	<div class="page">
		<van-nav-bar :title=" this.xuanfeidata.xuanfei_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="name" style="display: none;">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="title" style="display: none;">{{ this.xuanfeidata.vo_title }}</p>
			<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v, k) in xuanfeidata.img_url" :key="k">
						<img class="banner_img" :src="v" />
						
					</swiper-slide>
				</swiper>
			<div style="text-align: left;background-color: #fff;padding: 10px;margin: 10px 0" v-html="xuanfeidata.content">
			</div>
			<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #f806e4 , #e6c3a1)">{{$t("foorter.subscribe")}}</van-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
			bannerSwiperOption: {
					//effect: 'coverflow',
					
					slidesPerView: 1,
					speed: 800,
					autoplay: true,
					//loop:true,
					
				}
		};
	},
	methods: {
		back() {
			this.$router.go(-1)
			//this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				console.log(this.xuanfeidata)
			});
		},
		yuyue() {
			this.$toast(this.$t("reservation.counselor"));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.name {
	font-size: 1.125rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
</style>
<style lang="less" scoped>
.banner_swiper {
		height: 100%;
		width: 100%;

		.swiper-slide {
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 800px;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			background-color: #ffffff;
			background-position: center;
			background-size: cover;
			color: #ffffff;
		}
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	.banner_img {
		border-radius: 10px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>