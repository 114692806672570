<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="Info.name" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="loginForm">
      <van-field
        v-model="price"
        clearable
        input-align="center"
        class="input"
        :placeholder="$t('recharge.input_money')"
      />
      <div style="width: 100%; padding: 20px; color: red">
        {{ $t("recharge.recharge") }}:{{ this.Info.min }} - {{this.Info.max }}
      </div>
      <ul>
        <li
          v-for="(item, index) in Info.amount_channels.split('/')"
          :key="index"
          style="float: left"
        >
          <div
            style="
              width: 100%;
              line-height: 30px;
              background-color: #fff;
              text-align: center;
              margin: 18px 10px 0px 10px;
              width: 115px;
              border-radius: 15px;
            "
            @click="changeval(item)"
          >
            {{ item }}
          </div>
        </li>
      </ul>
      <div style="margin-top: 40px; width: 100%">
        <van-button
          class="login-btn"
          type="primary"
          size="normal"
          @click="doRe()"
          >{{ $t("my.recharge") }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToRecharge",
  data() {
    return {
      Info: {
        amount_channels: "",
        name: "",
      },
      price: null,
    };
  },
  mounted() {
    this.Info = JSON.parse(this.$route.query.id);
  },
  methods: {
    back() {
      return window.history.back();
    },
    changeval(v) {
      this.price = v;
    },
    doRe() {
        if(!(this.price && this.price.length > 0)){
            this.$toast(this.$t("recharge.input_money"));
            return false;
        }
        if(isNaN(this.price)){
            this.$toast(this.$t("recharge.input_money"));
            return false;
        }
        
      if (this.price < parseFloat(this.Info.min) || this.price > parseFloat(this.Info.max)) {
        this.$toast(this.$t("recharge.input_money"));
        return false;
      }
      this.$http({
        url: "to_recharge",
        method: "post",
        data: { 
          payid:this.Info.id, 
          price:this.price,
          type:this.Info.type
        },
      })
     .then((result) => {
      console.log(result)
        if(result.code == 200)
          this.jumpUrl(result.data.url);
        else{
          this.$toast(result.msg);
        }
      });
    },
    jumpUrl (url) {
      const _u = navigator.userAgent
      const ios = _u.indexOf('iPhone') > -1
      const iPad = _u.indexOf('iPad') > -1
      if (ios || iPad) {
        window.location.replace(url)
      } else {
        if (url.includes('https://')) {
          window.open(url)
        } else {
          window.location.href = url
        }
      }
    }
  },
};
</script>


<style lang='less' scoped>
@import "../../assets/css/base.css";

.loginForm {
  padding: 20px 10px;
}
.loginForm .input {
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.loginForm .active {
}
.loginForm .login-btn {
  margin-top: 85px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background-color: #f806e4;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
</style>